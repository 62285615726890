import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import rootMutations from "./mutations.js";
import rootActions from "./actions.js";
import rootGetters from "./getters.js";

const store = createStore({
  plugins: [
    createPersistedState({
      paths: ["compareTrucks", "compareTrailers"],
    }),
  ],
  state() {
    return {
      allTrucks: null,
      allTrailers: null,
      compareTrucks: [],
      compareTrailers: [],
      sortTruck: "",
      sortTrailer: "",
      isLoading: false,
      isCategory: false,
      category: "",
    };
  },
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
});

export default store;
