<template>
  <nav-bar></nav-bar>
  <router-view></router-view>
  <div @click="toTop" class="top-btn px-2">
    <div>
      <fa class="fa" :icon="['fas', 'angle-up']" />
    </div>
    <div>Top</div>
  </div>
</template>

<script>
import NavBar from "./components/UI/NavBar.vue";
import { toTop, getScroll } from "./utils";
export default {
  components: {
    NavBar,
  },
  methods: { toTop },
  mounted() {
    getScroll();
  },
};
</script>

<style></style>
