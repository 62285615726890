export function getDays() {
  const arr = [];
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  let today = new Date().toLocaleTimeString("en-us", options);
  let tomorrow = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  ).toLocaleTimeString("en-us", options);
  let nextDay = new Date(
    new Date().getTime() + 48 * 60 * 60 * 1000
  ).toLocaleTimeString("en-us", options);
  today = today.slice(0, today.lastIndexOf(","));
  tomorrow = tomorrow.slice(0, tomorrow.lastIndexOf(","));
  nextDay = nextDay.slice(0, nextDay.lastIndexOf(","));
  arr.push(today, tomorrow, nextDay);
  return arr;
}
export function toTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function getScroll() {
  let mybutton = document.querySelector(".top-btn");
  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      mybutton.style.opacity = "1";
    } else {
      mybutton.style.opacity = "0";
    }
  }
}
export function addSpace(val) {
  if (!val) return "-";
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function capitalizeProp(val) {
  let str = val.split("_").join(" ");
  return str;
}
export function checkPositiveNumber(num) {
  return /^\d*\s?\d*$/.test(num);
}
export function formatDate(val) {
  if (!val) return;
  const weeks = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date, week, month, day, year, time;
  date = new Date(val);
  week = weeks[date.getDay()];
  month = months[date.getMonth()];
  day = date.getDate();
  year = date.getFullYear();
  time = date.toTimeString().slice(0, 5);
  return `${week} ${month}, ${day}, ${year} ${time}`;
}
export function checkEmail(val) {
  if (!val) return false;
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (val.match(validRegex)) {
    return false;
  } else {
    return true;
  }
}
