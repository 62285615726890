import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./views/HomePage.vue";
// const Inventory = () => import("./views/Inventory.vue");
const AboutUs = () => import("./views/AboutUs.vue");
const LeaseToOwn = () => import("./views/LeaseToOwn.vue");
const IslamicFinancing = () => import("./views/IslamicFinancing.vue");
const ConsignmentPage = () => import("./views/ConsignmentPage.vue");
const CreditApplication = () => import("./views/CreditApplication.vue");
const OurTeam = () => import("./views/OurTeam.vue");
const ContactPage = () => import("./views/ContactPage.vue");
const Trucks = () => import("./views/TrucksPage.vue");
const TruckDetail = () => import("./components/trucks/TruckDetail.vue");
const Trailers = () => import("./views/TrailersPage.vue");
const TrailerDetail = () => import("./components/trailers/TrailerDetail.vue");
const Compare = () => import("./views/ComparePage.vue");
const CompareTrailers = () => import("./views/CompareTrailers.vue");
const NotFound = () => import("./views/NotFound.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    // { path: "/inventory", component: Inventory },
    { path: "/trucks", component: Trucks },
    { path: "/trucks/:id", name: "truck", component: TruckDetail, props: true },
    { path: "/trailers", component: Trailers },
    {
      path: "/trailers/:id",
      name: "trailer",
      component: TrailerDetail,
      props: true,
    },
    { path: "/compare", component: Compare },
    { path: "/compare-trailer", component: CompareTrailers },
    { path: "/about", component: AboutUs },
    { path: "/lease-to-own", component: LeaseToOwn },
    { path: "/islamic-financing", component: IslamicFinancing },
    { path: "/consignment", component: ConsignmentPage },
    { path: "/credit", component: CreditApplication },
    { path: "/team", component: OurTeam },
    { path: "/contact", component: ContactPage },
    { path: "/:notFound(.*)", component: NotFound },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
