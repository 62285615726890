import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faCircleArrowRight,
  faBars,
  faLocationDot,
  faPhone,
  faUsers,
  faUser,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faMinus,
  faMagnifyingGlassPlus,
  faEnvelope,
  faVideo,
  faPrint,
  faTag,
  faImages,
  faXmark,
  faCalculator,
  faArrowDownWideShort,
  faFilter,
  faSort,
  faCalendarAlt,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faCircleArrowRight,
  faBars,
  faLocationDot,
  faPhone,
  faUsers,
  faUser,
  faAngleRight,
  faAngleLeft,
  faAngleUp,
  faAngleDown,
  faMinus,
  faMagnifyingGlassPlus,
  faEnvelope,
  faVideo,
  faPrint,
  faTag,
  faImages,
  faXmark,
  faCalculator,
  faArrowDownWideShort,
  faFilter,
  faSort,
  faCalendarAlt,
  faCircleInfo
);

export default FontAwesomeIcon;
