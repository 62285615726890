<template>
  <footer>
    <div class="container">
      <div class="row">
        <div
          class="col-10 d-md-flex justify-content-center gap-5 d-none mx-auto py-4"
        >
          <div class="child mt-3 mt-md-0">
            <h6 class="mb-2 fw-bold">
              <span class="text-yellow">QUICK</span> LINKS
            </h6>

            <div class="d-grid">
              <router-link to="/"> Home</router-link>
              <router-link to="/trucks"> Inventory</router-link>
              <router-link to="/consignment"> Consignment</router-link>
              <router-link to="/credit"> Credit Application</router-link>
            </div>
          </div>
          <div class="child mt-3 mt-md-0">
            <h6 class="mb-2 fw-bold">
              <span class="text-yellow">GET</span> IN TOUCH
            </h6>

            <div class="d-grid">
              <router-link to="/about"> About Us </router-link>
              <router-link to="/team"> Our team</router-link>
              <router-link to="/contact"> Contact</router-link>
              <a href="tel:630-756-2614">Call Now</a>
            </div>
          </div>
          <div class="child mt-3 mt-md-0">
            <h6 class="mb-2 fw-bold">LEASE</h6>

            <div class="d-grid">
              <router-link to="/lease-to-own">Lease to Own</router-link>
              <router-link to="/islamic-financing">
                Islamic Financing</router-link
              >
            </div>
          </div>
          <div class="child w-20 mt-3 mt-md-0">
            <h6 class="mb-2 fw-bold">
              <span class="text-yellow">OUR</span> LOCATION
            </h6>
            <span>5501 Adams ST Unit C Matteson, IL 60443</span> <br />
            <span
              ><a
                href="https://www.google.com/maps/place/Adams+St,+Matteson,+IL+60443,+%D0%A1%D0%A8%D0%90/@41.4975057,-87.7503792,17z/data=!3m1!4b1!4m5!3m4!1s0x880e112d1b18a8c3:0xeed461681889d531!8m2!3d41.4975057!4d-87.7481905"
                target="_blank"
                class="text-white"
                >Get Direction</a
              ></span
            >
          </div>
        </div>
        <div class="alternative-footer d-md-none">
          <div class="footer-wrap">
            <router-link to="/trucks">
              <button>INVENTORY</button>
            </router-link>
            <router-link to="/credit">
              <button>CREDIT APPLICATION</button>
            </router-link>
            <router-link to="/team">
              <button>OUR TEAM</button>
            </router-link>
            <router-link to="/about">
              <button>ABOUT US</button>
            </router-link>
            <router-link to="/contact">
              <button>CONTACT</button>
            </router-link>
            <div class="footer-location text-center">
              <h6 class="my-1">OUR LOCATION</h6>
              <p>E & A Truck Sales</p>
              <p>5501 Adams ST Unit C Matteson, IL 60443</p>
            </div>
            <a href="tel:630-756-2614"> <button>Call Now</button></a>
            <a
              href="https://www.google.com/maps/place/Adams+St,+Matteson,+IL+60443,+%D0%A1%D0%A8%D0%90/@41.4975057,-87.7503792,17z/data=!3m1!4b1!4m5!3m4!1s0x880e112d1b18a8c3:0xeed461681889d531!8m2!3d41.4975057!4d-87.7481905"
              target="_blank"
              class="text-white"
              ><button>Get Direction</button></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="sub-footer p-3">
      <p class="text-center w-75 mx-auto">
        Site Hosted By
        <a href="http://iitf.uz/" target="_blank" class="text-secondary"
          >IITF</a
        >
      </p>
      <p class="text-center w-75 mx-auto">
        © Copyright by INT IT FORCES, LLC. {{ new Date().getFullYear() }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  overflow-x: hidden;
  background: #0057b7;
  color: #fff;
}
.col-7 {
  padding: 1.4rem;
}
.sub-footer {
  background: #000;
  color: #767676;
}
.d-grid a {
  font-size: 1rem;
  color: #fff;
  margin: 4px 0;
  transition: all 0.12s ease;
}
p.w-75 {
  font-size: 0.8rem;
}
.w-20 {
  width: 20%;
  font-size: 1rem;
}
.w-20 span:last-child,
a.router-link-active {
  text-decoration: underline;
  cursor: pointer;
}
.w-20 h6 span:last-child {
  text-decoration: none;
}

.alternative-footer {
  background: #0057b7;
  box-shadow: 0 0 0 100vmax #0057b7;
  clip-path: inset(0 -100vmax);
}
.footer-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  padding: 0.8rem 0;
}
.footer-wrap a {
  width: 50%;
}
.footer-wrap button {
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  transition: all 0.12s ease-in;
}
.footer-wrap button:hover {
  background: #ddd;
  color: #000;
}
.footer-location {
  line-height: 1.2rem;
}
.text-yellow,
.d-grid a:hover,
a.router-link-active {
  color: #ffdd00;
}
</style>
