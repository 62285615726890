import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: "http://192.168.68.131:8000/api/v1/",
  // baseURL: process.env.VUE_APP_LOCAL_URL,
  // timeout: 15000,
  // headers: {
  //   // Authorization: "token ee0383cd78080ce5f5a2ad341c11a1fb5af239a9",
  //   Authorization: `token ${JSON.parse(localStorage.getItem("token"))}`,
  // },
});

customAxios.interceptors.request.use((config) => {
  return config;
});

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default customAxios;
