<template>
  <nav
    class="d-flex align-items-center justify-content-between px-4 mt-xxl-4 mb-xxl-4 my-2"
  >
    <div class="logo">
      <router-link to="/">
        <img src="../../assets/logo.svg" alt="" class="d-none d-xxl-block" />
        <img
          src="../../assets/llogo.svg"
          alt=""
          class="w-80 mx-auto d-xxl-none d-block"
        />
      </router-link>
    </div>
    <div class="mb-2">
      <div
        class="top-nav d-flex align-items-center justify-content-end gap-4 mb-2 position-relative"
      >
        <h2>
          <a href="tel:312-678-0974">CALL (312) 678-0974</a>
        </h2>
        <div id="google_translate_element"></div>
      </div>
      <ul class="bottom-nav d-flex gap-3 mb-0">
        <li>
          <router-link to="/"><fa :icon="['fas', 'home']" /></router-link>
        </li>
        <li class="dd-link">
          <a href="#">Inventory</a>
          <ul class="dd">
            <li>
              <router-link to="/trucks">Trucks</router-link>
            </li>
            <li>
              <router-link to="/trailers"> Trailers </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/about">About Us</router-link>
        </li>
        <!-- <li class="dd-link">
          <a href="#">Lease</a>
          <ul class="dd">
            <li>
              <router-link to="/lease-to-own"> Lease to Own </router-link>
            </li>
            <li>
              <router-link to="/islamic-financing">
                Islamic Financing
              </router-link>
            </li>
          </ul>
        </li> -->
        <li>
          <router-link to="/consignment">Consignment</router-link>
        </li>
        <li>
          <router-link to="/credit">Credit Application</router-link>
        </li>
        <li>
          <router-link to="/team">Our team</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
    </div>
  </nav>
  <div class="mobile-nav p-2">
    <div class="d-flex gap-2">
      <button class="btn btn-light">
        <a
          href="https://www.google.com/maps/place/Adams+St,+Matteson,+IL+60443,+%D0%A1%D0%A8%D0%90/@41.4975057,-87.7503792,17z/data=!3m1!4b1!4m5!3m4!1s0x880e112d1b18a8c3:0xeed461681889d531!8m2!3d41.4975057!4d-87.7481905"
          target="_blank"
          ><fa :icon="['fas', 'location-dot']"
        /></a>
      </button>
      <button class="btn btn-light">
        <a href="tel:630-756-2614">
          <fa :icon="['fas', 'phone']" />
        </a>
      </button>
    </div>
    <a
      data-bs-toggle="offcanvas"
      href="#offcanvasExample"
      role="button"
      aria-controls="offcanvasExample"
    >
      MENU <fa :icon="['fas', 'bars']" />
    </a>
  </div>
  <div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-body">
      <ul>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/">Home</router-link>
        </li>
        <li class="lease">
          <a href="#">Inventory</a
          ><span @click="isInventory = !isInventory"
            ><fa :icon="['fas', 'angle-right']"
          /></span>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/about">About</router-link>
        </li>
        <!-- <li class="lease">
          <a href="#">Lease</a
          ><span @click="isLease = !isLease"
            ><fa :icon="['fas', 'angle-right']"
          /></span>
        </li> -->
        <li data-bs-dismiss="offcanvas">
          <router-link to="/consignment">Consignment</router-link>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/credit">Credit Application</router-link>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/team">Our Team</router-link>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
      <!-- <ul class="sub-link" :class="{ moveLeft: isLease }">
        <li @click="isLease = false" class="bg-secondary">
          <a href="#">
            <span class="mx-2"><fa :icon="['fas', 'angle-left']" /></span> Lease
          </a>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/lease-to-own"> Lease to Own </router-link>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/islamic-financing"> Islamic Financing </router-link>
        </li>
      </ul> -->
      <ul class="inventory-link" :class="{ moveLeft: isInventory }">
        <li @click="isInventory = false" class="bg-secondary">
          <a href="#">
            <span class="mx-2"><fa :icon="['fas', 'angle-left']" /></span>
            Inventory
          </a>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/trucks"> Trucks </router-link>
        </li>
        <li data-bs-dismiss="offcanvas">
          <router-link to="/trailers"> Trailers </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLease: false,
      isInventory: false,
    };
  },
};
</script>

<style scoped>
#google_translate_element {
  overflow-x: hidden;
}
.w-80 {
  width: 80%;
}
h2 {
  position: relative;
}
.logo::after {
  content: "TRUCK SALES";
  font-size: 4rem;
  font-weight: 400;
  color: #0d6efd;
  width: max-content;
  font-style: italic;
  position: absolute;
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
}
.mobile-nav {
  display: none;
  color: #0057b7;
}
.mobile-nav button {
  color: #fff;
  background: #0057b7;
}
.mobile-nav a {
  font-size: 1.3rem;
  color: #0057b7;
}
.mobile-nav button a {
  color: #fff;
}
li a {
  font-weight: 700;
  transition: all 0.12s ease;
  text-transform: uppercase;
}
li a:hover,
a.router-link-active {
  color: #a5a5a5;
}
.logo {
  position: relative;
  width: 280px;
}
img {
  width: 100%;
}
.dd-link {
  position: relative;
}
.dd-link:hover .dd {
  opacity: 1;
}
.dd {
  position: absolute;
  left: 0;
  width: max-content;
  padding: 0;
  background: #f1f1f1;
  font-size: 0.9rem;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #fff;
}
.dd li {
  font-size: 0.86rem;
  padding: 1px 12px 1px 4px;
}
.dd li:hover {
  background: #e4e4e4;
}
.dd li:hover a {
  color: #000;
}
.dd li:first-child {
  border-bottom: 1px solid #fff;
}
.offcanvas {
  width: 320px;
  overflow-x: hidden;
}
.offcanvas-body {
  padding: 0;
  background: #0057b7;
}
.offcanvas-body ul {
  padding: 0;
}
.offcanvas-body ul li {
  border-bottom: 1px solid #fff;
  transition: all 0.05s ease;
  font-size: 0.9rem;
  cursor: pointer;
}
.offcanvas-body a {
  display: block;
  height: 100%;
  width: 100%;
  color: #fff;
  font-weight: 500;
  padding: 8px;
}
.offcanvas-body .lease {
  position: relative;
}
.offcanvas-body .lease span {
  color: #fff;
  display: grid;
  place-content: center;
  width: 12%;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-left: 1px solid #fff;
}
.offcanvas-body .lease span:hover {
  background: #06407e;
}
.offcanvas-body li:hover:not(.lease) {
  background: #fff;
}
.offcanvas-body li:hover:not(.lease) a {
  color: #0057b7;
}
.sub-link,
.inventory-link {
  background: #0057b7;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  opacity: 0;
  top: 0;
  transition: all 0.4s ease;
  overflow: hidden;
}
.sub-link.moveLeft,
.inventory-link.moveLeft {
  opacity: 1;
  left: 0;
}
ul.sub-link li:first-child:hover a,
ul.inventory-link li:first-child:hover a {
  color: #fff !important;
}
@media screen and (min-width: 1800px) {
  .logo::after {
    font-size: 7rem !important;
    left: 150%;
  }
}
@media screen and (min-width: 1600px) {
  .logo::after {
    font-size: 5rem;
    left: 140%;
  }
}
@media screen and (min-width: 1400px) {
  .logo::after {
    font-size: 3.4rem;
  }
}
@media screen and (max-width: 1400px) {
  .logo::after {
    display: none;
    /* font-size: rem; */
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-nav,
  .top-nav {
    display: none !important;
  }
  nav {
    justify-content: center !important;
  }
}
</style>
