import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  getTrucks(state, payload) {
    state.allTrucks = payload.data;
  },
  getTrailers(state, payload) {
    state.allTrailers = payload.data;
  },
  addCompareTrucks(state, payload) {
    if (payload.add) {
      if (state.compareTrucks.length == 4) {
        return;
      }
      state.compareTrucks.push(payload.id);
    } else {
      state.compareTrucks = state.compareTrucks.filter(
        (truck) => truck != payload.id
      );
    }
  },
  addCompareTrailers(state, payload) {
    if (payload.add) {
      if (state.compareTrailers.length == 4) {
        return;
      }
      state.compareTrailers.push(payload.id);
    } else {
      state.compareTrailers = state.compareTrailers.filter(
        (truck) => truck != payload.id
      );
    }
  },
  addSortTruck(state, payload) {
    if (payload == "default") {
      state.sortTruck = "";
      return;
    }
    state.sortTruck = payload;
  },
  addSortTrailer(state, payload) {
    if (payload == "default") {
      state.sortTrailer = "";
      return;
    }
    state.sortTrailer = payload;
  },
  changeLoading(state, payload) {
    state.isLoading = payload;
  },
  changeCategory(state, payload) {
    state.isCategory = !state.isCategory;
    state.category = payload;
  },
  setError(state, payload) {
    if (payload.request?.status == 404) {
      toast.error("Not Found");
      return;
    }
    if (payload.request?.status == 400) {
      for (let prop in payload.response?.data) {
        toast.error(prop + " : " + payload.response.data[prop][0]);
      }
    }
  },
};
