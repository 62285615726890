<template>
  <div class="re-btn-wrapper d-flex justify-content-between">
    <div class="first w-75 p-2">
      <p>protected by reCAPTCHA</p>
      <div class="d-flex">
        <a
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          >Privacy</a
        >
        <span aria-hidden="true" role="presentation"> - </span>
        <a href="https://www.google.com/intl/en/policies/terms/" target="_blank"
          >Terms</a
        >
      </div>
    </div>
    <div class="second d-flex align-items-center justify-content-center w-25">
      <img src="https://www.gstatic.com/recaptcha/api2/logo_48.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.re-btn-wrapper {
  width: 256px;
  height: 58px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.first {
  background: #1a73e8;
  color: #fff;
  font-size: 11px;
}
.first p {
  font-size: 13px;
}
.first a {
  color: #fff;
}
.second img {
  width: 60%;
}
</style>
