import customAxios from "../api";

export default {
  setError(context, payload) {
    context.commit("setError", payload);
  },
  changeLoading(context, payload) {
    context.commit("changeLoading", payload);
  },
  async getTrucks(context) {
    try {
      const res = await customAxios.get("trucks/");
      context.commit("getTrucks", res);
    } catch (e) {
      alert(e);
    }
  },
  async postfilterTrucks(context, payload) {
    try {
      const res = await customAxios.get(
        `trucks/?ordering=${context.state.sortTruck}&suspension=${
          payload.suspension
        }&country=${payload.country}&state=${payload.state}&city=${
          payload.city
        }&listing-type=${payload.listingType}&category=${
          payload.category
        }&manufacturer=${payload.manufacturer}&start-year=${
          payload.year.min
        }&end-year=${payload.year.max}&min-price=${
          payload.price.min
        }&max-price=${payload.price.max}&condition=${
          payload.condition
        }&start-date=${
          payload.date.min
            ? new Date(payload.date.min).toISOString().slice(0, 10)
            : ""
        }&end-date=${
          payload.date.max
            ? new Date(payload.date.max).toISOString().slice(0, 10)
            : ""
        }&last-days=${payload.lastDays}&drive=${payload.drive}&min-horsepower=${
          payload.horsePower.min
        }&max-horsepower=${payload.horsePower.max}&serial=${
          payload.serial
        }&min-mileage=${payload.milage.min}&max-mileage=${
          payload.milage.max
        }&min-gvw=${payload.gvw.min}&max-gvw=${payload.gvw.max}&fuel-type=${
          payload.fuel
        }&cab=${payload.cab}&transmission=${payload.transmission}&gvwr=${
          payload.gvwr
        }&engine-manufacturer=${payload.engineManufacturer}&number-of-speed=${
          payload.numberOfSpeed
        }&min-sleeper-size=${payload.sleeper.min}&max-sleeper-size=${
          payload.sleeper.max
        }&transmission-type=${payload.transmissionType}&stock=${payload.stock}`
      );
      context.commit("getTrucks", res);
    } catch (e) {
      alert(e);
    }
  },
  async getTrailers(context) {
    try {
      const res = await customAxios.get("trailers/");
      context.commit("getTrailers", res);
    } catch (e) {
      alert(e);
    }
  },
  async postFilterTrailers(context, payload) {
    try {
      const res = await customAxios.get(
        `trailers/?ordering=${context.state.sortTrailer}&serial=${
          payload.serial
        }&min-gvw=${payload.gvw?.min}&max-gvw=${payload.gvw?.max}&stock=${
          payload.stock
        }&listing-type=${payload.listingType}&category=${
          payload.category
        }&manufacturer=${payload.manufacturer}&condition=${
          payload.condition
        }&start-date=${
          payload.date.min
            ? new Date(payload.date.min).toISOString().slice(0, 10)
            : ""
        }&end-date=${
          payload.date.max
            ? new Date(payload.date.max).toISOString().slice(0, 10)
            : ""
        }&last-days=${payload.lastDays}`
      );
      context.commit("getTrailers", res);
    } catch (e) {
      alert(e);
    }
  },
};
