export default {
  // allModifiedTrucks: (state) => {
  //   state.allTrucks;
  // },
  // allModifiedTrucks changed to allTrucks
  compareTrucks: (state) => state.compareTrucks,
  compareTrailers: (state) => state.compareTrailers,
  compareTrailerImages(state) {
    return state.allTrailers
      ?.filter((trailer) => state.compareTrailers.indexOf(trailer.id) != -1)
      .map((trailer) => {
        return {
          id: trailer.id,
          img: trailer.media ? trailer.media[0].image : "-",
        };
      });
  },
  compareImages(state) {
    return state.allTrucks
      ?.filter((truck) => state.compareTrucks.indexOf(truck.id) != -1)
      .map((truck) => {
        return {
          id: truck.id,
          img: truck.media ? truck.media[0].image : "-",
        };
      });
  },
  allCompareTrucks(state, getters) {
    return getters.allTrucks?.filter(
      (truck) => state.compareTrucks.indexOf(truck.id) != -1
    );
  },
  allCompareTrailers(state, getters) {
    return getters.allTrailers?.filter(
      (trailer) => state.compareTrailers.indexOf(trailer.id) != -1
    );
  },
  allTrailers(state) {
    return state.allTrailers;
  },
  allTrucks(state) {
    return state.allTrucks;
  },
};
