import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./css/main.css";
import router from "./router.js";
import store from "./store";
import FontAwesomeIcon from "./fontawesome.js";
import BaseFooter from "./components/UI/BaseFooter.vue";
import CountryFlag from "vue-country-flag-next";
import VueSocialSharing from "vue-social-sharing";
import RecaptchaButton from "./components/UI/RecaptchaButton.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueSocialSharing);
app.use(Toast, {
  position: "top-right",
  timeout: 3010,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
});
app.component("fa", FontAwesomeIcon);
app.component("base-footer", BaseFooter);
app.component("country-flag", CountryFlag);
app.component("recaptcha-button", RecaptchaButton);
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js";
