<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
  </section>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col p-0 position-relative">
          <div
            id="carouselExampleFade"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="4000">
                <img
                  src="../assets/back1.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item" data-bs-interval="4000">
                <img
                  src="../assets/back2.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="home-welcome text-center">
      <h2>Welcome to E & A Truck Sales</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto welcome-p my-5">
          <p class="mb-2">
            E & A Truck Sales is Chicago-land’s Premier Truck and Trailer
            Resource. We have semi trucks for sale in Chicago and the
            surrounding area. We specialize in late model pre-owned Volvo
            trucks, Freightliner trucks and Utility dry van trailers. All of our
            used semi trucks and trailers have been through our truck service
            shop and are DOT inspected. Service and reputation are our highest
            priorities here.
          </p>
          <!-- <a :href="base + 'pdf/Pagesfromsandhillsmag.pdf'" target="_blank"
            ><fa class="icon" :icon="['fas', 'arrow-circle-right']" /> CLICK
            HERE TO SEE OUR LATEST PUBLICATION</a
          > -->
        </div>
      </div>
    </div>
  </section>
  <section class="mb-5 position-relative">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 mt-4">
          <div class="w-80 ms-md-auto mx-auto mt-5 mb-4 mb-md-0">
            <h4 class="text-primary">ABOUT US</h4>
            <h2 class="mb-3">WHY E & A TRUCK SALES</h2>
            <p>
              We strive to provide our customers with the most reliable trucks
              and trailers in the market. Providing our customers with the best
              trucks and trailers is our mission
            </p>
            <router-link to="/about">
              <button class="mt-3">LEARN MORE</button>
            </router-link>
          </div>
        </div>
        <div class="col-md-5 ms-lg-auto mx-auto">
          <div>
            <div class="about-images mt-2 mt-md-0 mx-auto">
              <img src="../assets/img1.jpg" alt="" />
              <img src="../assets/img2.jpg" alt="" />
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
    };
  },
};
</script>

<style scoped>
.w-80 {
  width: 80%;
}
.w-80 h2 {
  font-weight: 600;
}
.w-80 button {
  padding: 6px 12px;
  background: linear-gradient(to right, blue 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.25s ease-out;
  border: 1px solid #aaa;
}
.w-80 button:hover,
.w-80 button:focus {
  background-position: left bottom;
  color: #fff;
}
section {
  overflow-x: hidden;
}
.carousel-item {
  height: 110vh;
}
.carousel-item img {
  height: 100%;
}
.carousel-item:first-child img {
  object-fit: cover;
}
.carousel-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, #000, #000, 1);
  background: rgba(0, 0, 0, 0.2);
}
.home-title {
  padding: 12px;
  background: #db1f48;
}

.home-welcome h2 {
  font-weight: 700;
}
img {
  width: 100%;
}
.welcome-p {
  font-size: 18px;
}
.icon,
.welcome-p a {
  color: #db1f48;
}
.about-images {
  position: relative;
  height: 500px;
}
.about-images div {
  position: absolute;
  top: 8%;
  left: 8%;
  height: 350px;
  width: 350px;
  border: 10px solid #1565bb;
}
.about-images img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  filter: grayscale(100%);
}
.about-images img:first-child {
  position: absolute;
  left: 16%;
}
.about-images img:nth-child(2) {
  position: absolute;
  top: 16%;
}
@media screen and (max-width: 992px) {
  .about-images img,
  .about-images div {
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .carousel-item {
    height: 60vh;
  }
  .w-80 {
    text-align: center;
  }
  .about-images div {
    top: 7%;
    left: 22%;
  }
  .about-images img:first-child {
    left: 26%;
  }
  .about-images img:nth-child(2) {
    top: 14%;
    left: 16%;
  }
}
@media screen and (max-width: 576px) {
  .about-images {
    height: 350px;
  }
  .welcome-p {
    font-size: 0.95rem;
  }
  .about-images div {
    top: 6%;
    left: 8%;
  }
  .about-images img:first-child {
    left: 14%;
  }
  .about-images img:nth-child(2) {
    top: 14%;
    left: 4%;
  }
}
</style>
